<template>
  <tr class="text-center">
    <td class="border px-4 py-2">{{ name }}</td>
    <td class="border px-4 py-2">
      <select v-model="localStaffData[name].monday" class="border-2 rounded w-14">
        <option value="">Select</option>
        <option v-for="option in foodOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
      </select>
    </td>
    <td class="border px-4 py-2">
      <select v-model="localStaffData[name].tuesday" class="border-2 rounded w-14">
        <option value="">Select</option>
        <option v-for="option in foodOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
      </select>
    </td>
    <td class="border px-4 py-2">
      <select v-model="localStaffData[name].wednesday" class="border-2 rounded w-14">
        <option value="">Select</option>
        <option v-for="option in foodOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
      </select>
    </td>
    <td class="border px-4 py-2">
      <select v-model="localStaffData[name].thursday" class="border-2 rounded w-14">
        <option value="">Select</option>
        <option v-for="option in foodOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
      </select>
    </td>
    <td class="border px-4 py-2">
      <select v-model="localStaffData[name].friday" class="border-2 rounded w-14">
        <option value="">Select</option>
        <option v-for="option in foodOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
      </select>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'StaffTableRow',
  props: {
    name: String,
    staffData: Object,
    componentKey: Number
  },
  data() {
    return {
      localStaffData: {...this.staffData},
      foodOptions: [
        { value: 1, text: 'Menü 1' },
        { value: 2, text: 'Menü 2' },
        { value: 3, text: 'Menü 2 vegetarisch' }
      ]
    }
  },
  watch: {
    staffData: {
      handler(newValue) {
        this.localStaffData = {...newValue};
      },
      deep: true
    }
  },
  components: {
  },
  created() {
  }
}
</script>