import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import EssensplanView from "@/components/Essensplan.vue";
import AdminView from '@/components/Admin.vue'
import WeekOverview from "@/components/WeekOverview.vue";

const routes = [
    { path: '/', component: WeekOverview },
    { path: '/admin', component: AdminView },
    { path: '/week', component: EssensplanView }
]

const router = new createRouter({
    history: createWebHistory(),
    routes // short for `routes: routes`
});

import './css/tailwind.css'

createApp(App).use(router).mount('#app')
