<template>
  <div class="flex justify-center items-center flex-col min-h-screen w-screen bg-gray-200">
    <div class="bg-white shadow-md rounded px-8 pt-14 pb-6 mb-4 flex flex-col justify-center items-center p-5 max-w-2xl">
      <img class="w-full h-auto" :src="imagePath" alt="Food Schedule" v-if="imagePath">
    </div>
    <div class="bg-white shadow-md rounded px-8 pt-14 pb-6 mb-4 flex flex-col justify-center items-center staff-table max-w-5xl mx-auto">
      <p>
        Bitte auswählen, welches Menü Du essen möchtest
      </p>
      <StaffTable />
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import StaffTable from "@/components/StaffTable.vue";
export default {
  name: 'EssensplanView',
  components: {
    StaffTable
  },
  data() {
    return {
      imagePath: null
    }
  },
  created() {
    const router = useRouter();
    const week = router.currentRoute.value.query.week;
    if (week) {
      this.imagePath = `/api/uploads/food_schedule/week-${week}.jpg`;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>