<template>
  <nav class="bg-black p-4 absolute top-0 left-0 w-full z-10">
    <router-link class="text-white mr-4 hover:text-gray-300" to="/">Home</router-link>
    <router-link class="text-white mr-4 hover:text-gray-300" to="/admin">Admin</router-link>
  </nav>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>

</style>
