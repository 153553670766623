<template>
  <div class="admin flex flex-col items-center justify-center h-screen bg-gray-200">
    <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" @submit.prevent="onSubmit">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="file">
          Essensplan:
        </label>
        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="file" id="file" @change="onFileChange" name="file">
      </div>
      <div class="mb-6">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="filename">
          Kalenderwoche:
        </label>
        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" type="text" id="filename" v-model="filename" name="filename">
      </div>
      <div class="flex items-center justify-between">
        <button class="bg-black hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
          Upload
        </button>
      </div>
    </form>

    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
      <button @click="exportWeek(weekNumber)" v-for="weekNumber in weekNumbers" :key="weekNumber" class="bg-black hover:bg-blue-700 m-4 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        Export Essensplan für Kalenderwoche {{ weekNumber }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'AdminView',
  data() {
    return {
      file: null,
      filename: '',
      weekNumbers: []
    }
  },
  components: {
  },
  props: [
  ],
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
    },
    onSubmit() {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('filename', this.filename);

      axios.post('https://essensplan.mymefa.com/api/upload.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            console.log(response.data);
          })
          .catch(error => {
            console.error(error);
          });
    },
    fetchWeekNumbers() {
      axios.get('https://essensplan.mymefa.com/api/check-weeks.php')
          .then(response => {
            this.weekNumbers = response.data.weekNumbers;
          })
          .catch(error => {
            console.error(error);
          });
    },
    exportWeek(weekNumber) {
      axios.get(`https://essensplan.mymefa.com/api/export-week.php?week=${weekNumber}`)
          .then(response => {
            let downloadLink = response.data.downloadLink;
            window.open('https://essensplan.mymefa.com/api/' + downloadLink, '_blank');
          })
          .catch(error => {
            console.error(error);
          });
    }
  },
  created() {
    this.fetchWeekNumbers();
  }
}
</script>